export default function captureThumbnail(
    canvas,
    width,
    height,
    format = 'image/png'
) {
    return new Promise((resolve, reject) => {
        try {
            const thumbnailCanvas = document.createElement('canvas');
            thumbnailCanvas.width = width;
            thumbnailCanvas.height = height;
            const ctx = thumbnailCanvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, width, height);
            ctx.drawImage(canvas, 0, 0, width, height);
            const imageDataURL = thumbnailCanvas.toDataURL(format);
            resolve(imageDataURL);
        } catch (error) {
            reject(error);
        }
    });
}
